import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

function formatDate(dateStr) {
  // format date to Y-m-d H:i:s
  const date = new Date(dateStr)
  const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
  return formattedDate
}

// set root user access
const userData = JSON.parse(localStorage.getItem('userData'))
let adminSuffix = ''
if (userData.role.id === 1) {
  adminSuffix = 'AsRoot'
}

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'primary',
        label: 'Event',
      },
      {
        color: 'danger',
        label: 'Landing',
      },
      {
        color: 'info',
        label: 'Contest',
      },
      {
        color: 'warning',
        label: 'Survey',
      },
      /* {
        color: 'success',
        label: 'Holiday',
      }, */
    ],
    selectedCalendars: ['Event', 'Landing', 'Contest', 'Survey'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { campaignId }) {
      return new Promise((resolve, reject) => {
        useJwt[`getCampaignEvents${adminSuffix}`](campaignId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const newEvent = {
          name: event.title,
          start_datetime: formatDate(event.start),
          end_datetime: formatDate(event.end),
          destination_url: event.extendedProps.destination_url,
          default: event.extendedProps.default_event,
        }
        useJwt[`createCampaignEvent${adminSuffix}`](campaignId, newEvent)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        const newEvent = {
          name: event.title,
          start_datetime: formatDate(event.start),
          end_datetime: formatDate(event.end),
          destination_url: event.extendedProps.destination_url,
          default: event.extendedProps.default_event,
        }
        useJwt[`editCampaignEvent${adminSuffix}`](event.id, newEvent)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt[`deleteCampaignEvent${adminSuffix}`](id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
